
































































import { Component, Inject as VueInject, Ref } from 'vue-property-decorator'
import { Location } from 'vue-router/types/router'
import { QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'
import _throttle from 'lodash/throttle'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { PaginationProps } from '../../../dsl/molecules/Pagination'
import { ImageProps } from '../../../dsl/atoms/Image'
import { AbstractLinkProps } from '../../../dsl/abstract/components/Link/Link.contracts'

import {
  CartItemData,
  ContentSneakPeakData,
  FilterParamConfig, ISiteService,
  NavigationItem,
  ResolvedBannerData, SiteServiceType
} from '../../../contexts'
import { FiltersWrapper } from '../../../front/shared/organisms/FiltersWrapper'
import { Inject, PARENT_CONTENT_DATA_KEY } from '../../../support'
import { ProductCard } from '../../../front/products/organisms/ProductCard'
import {
  ProductCardProps
} from '../../../front/products/organisms/ProductCard/ProductCard.contracts'
import {
  translateProductToProductCard
} from '../../../front/products/organisms/ProductCard/ProductCard.helpers'
import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract/ui'
import { HeadingFormData } from '../../partials'

import { ProductsListModule } from '../ProductsList.contracts'
import { PRODUCTS_LIST_COMPONENT_KEY, PRODUCTS_LIST_DEFAULT_CONFIG } from '../ProductsList.config'

/**
 * Container component for the `ProductsListModuleUi`.
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductsListModuleUi>({
  name: 'ProductsListModuleUi',
  components: { ProductCard, FiltersWrapper },
  created (): void {
    this.config = this.getComponentConfig(
      PRODUCTS_LIST_COMPONENT_KEY,
      { ...PRODUCTS_LIST_DEFAULT_CONFIG }
    )
    /**
     * @inheritDoc
     */
    this.initCategoryNavigation()
  },
  mounted (): void {
    this.calculateProductsPerRow()
    this.registerListeners()
    this.getHeadingHeight()

    const getFirstVariant = (item: ProductCardProps) => {
      return Object.values(item.variants)[0]
    }
    const getFirstVariantKey = (item: ProductCardProps) => {
      const key = Object.keys(item.variants)[0] ?? ''
      return (key !== '_') ? key : ''
    }

    this.eventBus.emit('app:product_list.view',
      {
        items: this.products.map((item) => {
          const variant = getFirstVariant(item)
          const variantSlug = getFirstVariantKey(item)
          return {
            id: variant.sku,
            parent_id: item.sku,
            variant: variantSlug,
            name: variant.name,
            category: variant.attributes.mainCategory,
            brand: variant.attributes.brand,
            currency: this.siteService.getActiveSite().currency,
            price: variant.price.finalPrice,
            url: this.siteService.getActiveSiteAddress() + `${item.urlPath}`,
            image: variant && (Array.isArray(variant.images) &&
              variant.images.length > 0)
              ? this.siteService.getActiveSiteAddress() +
              variant.images[0].url : ''
          }
        })
      }
    )
  }
})
export class ProductsListModuleUi extends AbstractModuleUi<ProductsListModule> {
  @VueInject({ from: PARENT_CONTENT_DATA_KEY })
  public readonly contentData!: ContentSneakPeakData

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  @Ref('productsList')
  public readonly productsListRef?: HTMLDivElement

  @Ref('productListTop')
  public readonly productListTopRef?: HTMLDivElement

  public productsPerRow = 4

  public categoryNav?: NavigationItem[]

  public currentPage: number = this.$route.query?.page
    ? parseInt(this.$route.query.page.toString(), 10) : 1

  public isWide: boolean = false

  /**
   * Determines products list banners.
   */
  public get banners (): ResolvedBannerData[] | undefined {
    if (this.data.content.banners?.length === 0) {
      return
    }

    return this.data.content.banners
  }

  /**
   * Filters that behaves like links
   */
  public get categoryNavLinks (): NavigationItem[] | undefined {
    if (!this.categoryNav || this.categoryNav.length === 0) {
      return
    }

    return this.categoryNav
  }

  public get basePaginationProps (): Omit<PaginationProps, 'currentPage'> {
    return {
      showDirectionButtons: true,
      totalPages: Math.ceil(this.content.total / this.perPage),
      totalVisible: 5,
      link: { target: { path: this.$route.path, query: this.filterQuery }, param: 'page' }
    }
  }

  public get filterParams (): FilterParamConfig[] {
    return this.content.filters ?? []
  }

  public get filterQuery (): QueryParams {
    return {
      perPage: this.content.defaultPerPage,
      sort: this.content.defaultSort,
      ...this.$route.query
    }
  }

  public get hasSimplePagination (): boolean {
    return !!this.content.hasSimplePagination
  }

  public get heading (): HeadingFormData | undefined {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  public get totalItemsCount (): number | undefined {
    if (!this.content.total) {
      return
    }

    return this.content.total
  }

  public get buttonIcon (): string | null {
    return this.getConfigProperty('buttonIcon')
  }

  public get listDisplayControlsInDrawer (): boolean {
    return this.getConfigProperty<boolean>('listDisplayControlsInDrawer')
  }

  public get paginationHasDirectionButtons (): boolean {
    return this.getConfigProperty<boolean>('paginationHasDirectionButtons')
  }

  public get paginationHasGroupedLayout (): boolean {
    return this.getConfigProperty<boolean>('paginationHasGroupedLayout')
  }

  public get shouldImplementMobileHeading (): boolean {
    return this.getComponentConfig<boolean>('shouldImplementMobileHeading')
  }

  public get perPage (): number {
    return this.$route.query.perPage
      ? parseInt(this.$route.query.perPage as string, 10)
      : this.content.defaultPerPage
  }

  public get products (): ProductCardProps[] {
    if (!this.content.products?.length) {
      return []
    }

    const products = this.content.products

    if (this.hasSimplePagination) {
      return products.splice(0, (this.showedRow * this.productsPerRow))
    }

    return products
  }

  public get showedRow (): number {
    return this.content.initialRowAmount || 0
  }

  public get showPagination (): boolean {
    if (typeof this.content.showPagination === 'undefined') {
      return this.perPage < this.content.total && !this.content.hasSimplePagination
    }

    return this.content.showPagination
  }

  public get showSimplePagination (): boolean {
    return this.hasSimplePagination && ((this.content.products?.length || 0) - (this.showedRow *
      this.productsPerRow)) > 0
  }

  public calculateProductsPerRow (): void {
    if (!this.productsListRef || this.productsListRef.children.length === 0) {
      this.productsPerRow = 0
      return
    }

    this.productsPerRow = Math.floor(this.productsListRef.clientWidth /
      this.productsListRef.children[0].clientWidth)
  }

  public showNextRow (): void {
    if (!this.content.initialRowAmount) {
      return
    }

    this.content.initialRowAmount++
  }

  public updateQuery (query: Location['query']): void {
    this.setQuery(query)
  }

  public updateFiltersVisibility (visibility: boolean): void {
    this.isWide = !visibility
  }

  public findBanner (zeroBasedIndex: number): ResolvedBannerData | undefined {
    const index = zeroBasedIndex + 1
    return this.banners?.find((banner) => banner.index.toString() === `${index}`)
  }

  public itemClass (zeroBasedIndex: number): string {
    const foundBanner = this.findBanner(zeroBasedIndex)
    if (!foundBanner) {
      return ''
    }

    return `width-${foundBanner.width}`
  }

  public showBanner (zeroBasedIndex: number): boolean {
    const index = zeroBasedIndex + 1
    return !!this.banners && this.banners.some((banner) => banner.index.toString() === `${index}`)
  }

  public imageProps (index: number): ImageProps {
    const foundBanner = this.findBanner(index)
    if (!foundBanner) {
      return {
        src: '',
        alt: ''
      }
    }

    const image = this.isMobile() ? (foundBanner.imageMobile ?? foundBanner.image) : foundBanner.image

    return toImageProps(image)
  }

  public linkProps (index: number): Pick<AbstractLinkProps, 'target'> {
    const foundBanner = this.findBanner(index)
    if (!foundBanner?.link) {
      return {
        target: ''
      }
    }

    return { target: foundBanner.link.url }
  }

  public setPage (page: number) {
    this.currentPage = page
    this.setQuery({
      ...this.$route.query,
      page: page.toString()
    })
  }

  protected getHeadingHeight () {
    if (!this.productListTopRef) {
      return
    }

    const heading = this.productListTopRef.querySelector('.heading')

    if (!heading) {
      return
    }

    document.documentElement.style.setProperty(
      '--product-list-heading',
      heading.scrollHeight + 'px'
    )
  }

  /**
   * Compose filters that should behave like links
   * @protected
   */
  protected initCategoryNavigation (): void {
    const hasCategoryNav = this.getConfigProperty('hasCategoryNavigation', this.config)

    if (!hasCategoryNav) {
      this.categoryNav = undefined
    } else {
      if (!this.data.content.categoryNav) {
        this.categoryNav = undefined
      } else {
        this.loadCategoryChildren(this.data.content.categoryNav)
      }
    }
  }

  protected loadCategoryChildren (data: NavigationItem[]): void {
    const activeSitePath = this.contentData.urlPath

    if (activeSitePath) {
      for (const item of data) {
        if (String(item.target).charAt(0) === '/') {
          const blankPath = String(item.target).slice(1, String(item.target).length)

          if (blankPath === activeSitePath) {
            this.categoryNav = item.children
            return
          }

          this.loadCategoryChildren(item.children)
        } else {
          this.categoryNav = []
        }
      }
    } else {
      this.categoryNav = []
    }
  }

  protected registerListeners (): void {
    if (!this.hasSimplePagination) {
      return
    }

    this.addEventListener('resize', _throttle(this.calculateProductsPerRow, 100))
    this.addEventListener('resize', _throttle(this.getHeadingHeight, 100))
  }

  protected setQuery (query: Location['query']): void {
    this.$router.push({
      path: this.$route.path,
      query
    })
  }
}

export default ProductsListModuleUi
