


















import { Component, Vue } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '../../../support'

import { Modals } from '../../shared/config/modals'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ManageCookies>({
  name: 'ManageCookies'
})
export class ManageCookies extends Vue {
  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  public openPrivacyModal (): void {
    this.modalConnector.open(Modals.PrivacyModal, { enableWhenAllPreferencesSet: true }, { closeOnClickOutside: false })
  }
}

export default ManageCookies
